<template>
  <div>
    <!--begin::Chart-->
    <apexchart class="statistics-widget-3-chart card-rounded-bottom" :options="options" :series="series" :height="250"
      type="pie"></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>

export default {
  props: ["moduleName", "classroom", "bulletins"],
  data() {
    return {
      options: {},
      series: [0, 0, 0, 0],
      labelColor: "#3F4254",
      colors: ["#0095E8", "#67c23a", "#D9214E"],
      lightColors: ["#FFF5F8", "#e8fff3", "#F1FAFF"],
    };
  },

  watch: {
    moduleName: function (value) {
      this.updateChart();
    },

    bulletins: function (value) {
      this.updateChart();
    },

    classroom: function (value) {
      this.updateChart();
    },
  },

  created() {
    this.updateChart();
  },

  methods: {
    async updateChart() {
      const marksSerie = [0, 0, 0, 0];

      this.bulletins.students.forEach((student) => {
        student.modules.forEach((m) => {
          if (m.name === this.moduleName && (m.moyenne !== "" || m.moyenne !== "--")) {
            console.log('test')
            if (Number(m.moyenne) > 15) marksSerie[0]++;
            else if (Number(m.moyenne) > 12) marksSerie[1]++;
            else if (Number(m.moyenne) > 9) marksSerie[2]++;
            else marksSerie[3]++;
          }
        })
      })

      this.updateOptions();
      this.updateSeries(marksSerie);
    },

    updateSeries(marksSerie) {
      this.series = marksSerie;
    },
    updateOptions() {
      this.options = {
        chart: {
          height: 250,
          type: "pie",
        },
        labels: [
          this.$t("bulletin.plus15"),
          this.$t("bulletin.plus12"),
          this.$t("bulletin.plus9"),
          this.$t("bulletin.mois9"),
        ],
        colors: ["#b1eb36", "#fab13c", "#028acf", "#fb4d3d"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
  },
};
</script>
